import type { MaybeRef } from "vue";
import type { IApiMedia } from "~/models/generated/IApiMedia";
import type { AsyncData, UseFetchOptions } from "#app";

export type PaginationOptions = {
  pageSize?: MaybeRef<number>,
  pageIndex?: MaybeRef<number>
};

export type ArticlesDto = {
  items: ArticleDto[],
  total: number
};

export type ArticleDto = {
  parentId: string | null,
  parentName: string | null,
  parentType: string | null,
  title: string,
  description?: string,
  image?: IApiMedia,
  icon?: string,
  url: string,
  showItem: boolean
};

export function useArticleCards () {
  // eslint-disable-next-line max-len
  function getByIds ( ids: MaybeRef<string[]>, currentId: MaybeRef<string>, options?: PaginationOptions ): Promise<AsyncData<ArticlesDto, Error>> {
    const computedUrl = computed( () => {
      let url_ = "/api/article/cards?";

      if ( ids === undefined || ids === null ) {
        throw new Error( "The parameter 'ids' must be defined." );
      }

      if ( currentId === undefined || currentId === null ) {
        throw new Error( "The parameter 'currentId' must be defined." );
      }

      const idsUnref = unref( ids );
      if ( idsUnref.length ) {
        url_ += idsUnref.map( ( id ) => `ids=${id}&` ).join( "" );
      }

      const currentIdUnref = unref( currentId );
      if ( currentIdUnref ) {
        url_ += `&currentId=${currentIdUnref}`;
      }

      const pageIndexUnref = unref( options?.pageIndex );
      if ( pageIndexUnref ) {
        url_ += `&pageIndex=${pageIndexUnref}`;
      }

      const pageSizeUnref = unref( options?.pageSize );
      if ( pageSizeUnref ) {
        url_ += `&pageSize=${pageSizeUnref}`;
      }

      url_ += "&expand=all";

      url_ = url_.replace( /[?&]$/, "" );

      return url_;
    } );

    const options_: UseFetchOptions<ArticlesDto> = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      watch: [computedUrl],
    };

    return useApiFetch<ArticlesDto>( computedUrl, options_ );
  }

  // eslint-disable-next-line max-len
  function getByType ( type: MaybeRef<string>, currentId: MaybeRef<string>, options?: PaginationOptions ): Promise<AsyncData<ArticlesDto, Error>> {
    const computedUrl = computed( () => {
      let url_ = "/api/article/cards/type?";

      if ( type === undefined || type === null ) {
        throw new Error( "The parameter 'type' must be defined." );
      }

      if ( currentId === undefined || currentId === null ) {
        throw new Error( "The parameter 'currentId' must be defined." );
      }

      const typeUnref = unref( type );
      url_ += `type=${typeUnref}`;

      const currentIdUnref = unref( currentId );
      if ( currentIdUnref ) {
        url_ += `&currentId=${currentIdUnref}`;
      }

      const pageIndexUnref = unref( options?.pageIndex );
      if ( pageIndexUnref ) {
        url_ += `&pageIndex=${pageIndexUnref}`;
      }

      const pageSizeUnref = unref( options?.pageSize );
      if ( pageSizeUnref ) {
        url_ += `&pageSize=${pageSizeUnref}`;
      }

      url_ += "&expand=all";

      url_ = url_.replace( /[?&]$/, "" );

      return url_;
    } );

    const options_: UseFetchOptions<ArticlesDto> = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      watch: [computedUrl],
    };

    return useApiFetch<ArticlesDto>( computedUrl, options_ );
  }

  return {
    getByIds,
    getByType,
  };
}
