<template>
  <component
    :is="tag"
    class="result-item">
    <span
      v-if="article.showItem"
      class="result-item__parent">
      <a-icon :icon="'fa:regular:' + getFaIcon(article.parentType)" />
      <span>
        {{ article.parentName }}
      </span>
    </span>
    <card-icon
      v-else
      class="result-item__icon"
      :image="article.image"
      :icon="article.icon"
      full-width-image
      default-icon="fa:regular:book-open"
      icon-size="32px" />

    <nuxt-link
      :to="article.url"
      class="result-item__link">
      <h3 class="result-item__heading">
        {{ article.title }}
      </h3>
    </nuxt-link>

    <p class="result-item__description">
      {{ article.description }}
    </p>

    <span class="result-item__action">
      Read more

      <span class="result-item__chevron">
        <a-icon icon="fa:regular:chevron-right" />
      </span>
    </span>
  </component>
</template>

<script setup lang="ts">
import type { ArticleDto } from "~/composables/useArticleCards";

withDefaults( defineProps<{
  article: ArticleDto,
  currentContentId: string,
  tag?: string
}>(), {
  tag: "div",
} );

function getFaIcon ( type: string | null ) {
  let result = "book-open";

  switch ( type ) {
    case "assessmentItem": {
      result = "box";
      break;
    }
    case "platformItem": {
      result = "layer-group";
      break;
    }
  }

  return result;
}

</script>

<style scoped lang="scss">
.result-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #aaa;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1;
  transition: background-color .2s ease-out;

  &:nth-child( 1 ),
  &:nth-child( 2 ) {
    .pinned-articles__link {
      border-top: 1px solid #aaa;
    }
  }

  @media (width <= 767px) {
    flex-basis: 100% !important;
    margin-right: 0;

    &:nth-child( 1 ),
    &:nth-child( 2 ) {
      .pinned-articles__link {
        border-top: none !important;
      }
    }
  }

  &__link {
    text-decoration: none !important;

    &::after {
      position: absolute;
      content: "";
      inset: 0;
    }
  }

  &__parent {
    display: flex;
    color: var( --color-secondary-500 );
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    align-items: center;

    ::v-deep( svg ) {
      flex-shrink: 0;
      display: inline-block;
      margin-right: 5px;
      vertical-align: text-top;
    }
  }

  &__icon {
    display: block;
    margin-bottom: 20px;
    color: var( --color-secondary-500 );
    font-size: 40px;

    ::v-deep( .card-icon__image ) {
      aspect-ratio: 16 / 9;
    }
  }

  &__heading {
    font-size: 1.25rem;
  }

  &__description {
    color: var( --text-color );
    flex-grow: 1;
  }

  &__action {
    text-transform: uppercase;
    font-weight: 500;
    color: var( --color-primary-500 );
  }

  &__chevron {
    display: inline-block;
    margin-left: 3px;
    top: -2px;
    position: relative;
  }

  &:hover {
    background: #f9f8f7;
  }
}
</style>
