<template>
  <div class="article-card-grid">
    <loading-overlay :pending="cards.pending.value">
      <layout-grid
        tag="ul"
        spacing="0 var(--s-4)"
        minimum="300px"
        repeat-count="auto-fill"
        class="article-card-grid__grid">
        <article-card
          v-for="(item, index) in cards.data.value?.items ?? []"
          :key="index"
          tag="li"
          :article="item"
          :current-content-id="content.id" />
      </layout-grid>
    </loading-overlay>

    <base-pagination
      v-if="pageCount > 1"
      :page-count="pageCount"
      :page-index="page"
      previous-prefix="fa:solid:chevron-left"
      :previous-suffix="null"
      :next-prefix="null"
      next-suffix="fa:solid:chevron-right"
      button-look="ghost"
      @next="onNext"
      @prev="onPrev"
      @change-page="onChangePage" />
  </div>
</template>

<script setup lang="ts">
import type { ContentItem } from "@edco/apollo";
import { useArticleCards } from "#imports";

const props = withDefaults( defineProps<{
  content: ContentItem,
  articleIds?: string[],
  articleType?: string,
  pageSize?: number
}>(), {
  articleIds: undefined,
  articleType: undefined,
  pageSize: 12,
} );

const page = ref( 0 );
const { getByIds, getByType } = useArticleCards();

const options = {
  pageIndex: page,
  pageSize: props.pageSize,
};

function loadData () {
  if ( props.articleIds ) {
    return getByIds( props.articleIds, props.content.id, options );
  }

  if ( props.articleType ) {
    return getByType( props.articleType, props.content.id, options );
  }

  throw new Error( "Missing article IDs or article type" );
}

const cards = await loadData();
const pageCount = computed( () => Math.ceil( ( cards.data.value?.total ?? 1 ) / props.pageSize ) );

function onNext () {
  page.value += 1;
}

function onPrev () {
  page.value -= 1;
}

function onChangePage ( pageIndex: number ) {
  page.value = pageIndex;
}
</script>

<style scoped lang="scss">
.article-card-grid {
  min-height: 100px;

  &__grid {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 1px;
  }
}

::v-deep( .result-item ) {
  border-top: 1px solid #aaa;
  margin-bottom: -1px;
}
</style>
